.module {
  position: absolute;

  width: 250px;
  padding: 20px;

  border: 1px solid rgba(116, 145, 165, 0.23);
  border-radius: 10px;
  background-color: #fff;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.name {
  margin-right: 10px;

  color: #42426a;
}

.select {
  width: 120px;
  height: 30px;

  &::before {
    top: 10px;
    right: 13px;
  }
}

.button {
  display: block;
  width: 120px;
  height: 36px;
  margin: 0 auto;

  border: 1px solid #f99f40;
  border-radius: 20px;
  color: #f99f40;

  transition: 0.2s;

  &:hover {
    border: none;
    color: #fff;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);
  }
}
