$animation-time: 0.3s;

.module {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;

  background-color: rgba(0, 0, 0, 0.79);

  animation: fadeIn $animation-time;

  &.withoutOverlay {
    background-color: transparent;
  }

  &.closing {
    animation: fadeOut $animation-time;

    .content {
      animation: up $animation-time;
    }
  }
}

.content {
  max-width: 100%;

  animation: down $animation-time;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes down {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
