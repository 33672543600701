.calculation {
  position: relative;

  background-image: linear-gradient(90deg, #6967ce 17.39%, #1cadd0 88.15%);

  &__bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;

    opacity: 0.15;
  }

  &__content {
    position: relative;

    padding-top: 76px;
    padding-bottom: 80px;
  }

  &__title {
    margin-bottom: 34px;

    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;

    color: #fff;
  }

  &__quiz {
    max-width: 325px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .calculation {
    &__content {
      padding-top: 81px;
    }

    &__title {
      margin-bottom: 18px;
    }

    &__quiz {
      max-width: none;
      margin: 0;
    }
  }
}

@media (min-width: 1200px) {
  .calculation {
    &__content {
      display: flex;
      justify-content: space-between;
      padding-top: 129px;
      padding-bottom: 125px;
    }

    &__title {
      align-self: center;
      max-width: 489px;
      margin-bottom: 0;

      font-size: 45px;
      line-height: 1.22;
      text-align: left;
    }

    &__quiz {
      width: 370px;
    }
  }
}
