.module {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 442px;
  max-width: 100%;
  padding: 50px 25px 30px;

  border-radius: 10px;
  background-color: #fff;
}

.close {
  position: absolute;
  top: 14px;
  right: 7px;

  width: 40px;
  height: 40px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(45deg);

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(-45deg);

    content: '';
  }
}

.name {
  align-self: flex-start;
  margin-bottom: 19px;

  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;

  color: transparent;
  background-image: linear-gradient(135.61deg, #6a67ce 30.59%, #1aafd0 73.14%);
  background-clip: text;
}

.slider {
  position: relative;

  margin-bottom: 26px;

  :global {
    .swiper-container {
      border-radius: 15px;
    }
  }
}

.slide {
  position: relative;

  display: flex;
  padding-bottom: 75%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 15px;
  }
}

:global {
  .detail-swiper-nav {
    display: none;
  }

  .detail-swiper-pagination {
    position: absolute;
    z-index: 10;
    left: 50%;
    bottom: 15px;

    display: flex;

    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 7px;
      height: 7px;

      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 7px;
      }

      &-active {
        background-image: linear-gradient(
          113.2deg,
          #fd636b 10.25%,
          #ffd200 98.71%
        );
      }
    }
  }
}

.zoom {
  display: none;
}

.text {
  max-height: 360px;
  overflow: auto;

  font-size: 13px;
  font-weight: 300;
  line-height: 1.58;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track-piece {
    border-radius: 4px;
    background-color: #f7f4ff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #dcdcdc;
  }
}

.button {
  width: 100%;
  height: 60px;
  margin-top: 41px;

  font-size: 15px;
  font-weight: 500;

  border-radius: 70px;
  color: #fff;
  background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

  transition: 0.2s;

  &:hover {
    box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
  }
}

@media (min-width: 768px) {
  .module {
    width: 720px;
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .module {
    width: 1140px;
    padding: 50px 60px 60px;
  }

  .name {
    align-self: center;
    margin-bottom: 40px;

    font-size: 28px;
  }

  .content {
    display: flex;
  }

  .slider {
    order: 1;
    flex: 1 0 480px;
    width: 480px;
    margin-bottom: 0;

    :global {
      .detail-swiper-nav {
        display: block;
      }

      .detail-swiper-nav-prev {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 18px;

        width: 72px;
        height: 72px;

        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.7);
        transform: translateY(-50%);

        transition: 0.2s;

        &::before {
          position: absolute;
          top: 29px;
          left: 31px;

          width: 14px;
          height: 14px;

          border-left: 1px solid #42426a;
          border-bottom: 1px solid #42426a;
          border-radius: 1px;
          transform: rotate(45deg);

          content: '';
        }

        &:hover {
          background-color: #fff;
          transform: translateY(-50%) scale(1.05);
        }

        &:active {
          transform: translateY(-50%);
        }
      }

      .detail-swiper-nav-next {
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 18px;

        width: 72px;
        height: 72px;

        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.7);
        transform: translateY(-50%);

        transition: 0.2s;

        &::before {
          position: absolute;
          top: 29px;
          right: 31px;

          width: 14px;
          height: 14px;

          border-right: 1px solid #42426a;
          border-bottom: 1px solid #42426a;
          border-radius: 1px;
          transform: rotate(-45deg);

          content: '';
        }

        &:hover {
          background-color: #fff;
          transform: translateY(-50%) scale(1.05);
        }

        &:active {
          transform: translateY(-50%);
        }
      }
    }
  }

  .slide {
    &:hover {
      background-size: initial;
    }
  }

  .textual {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    padding-right: 30px;
  }

  .text {
    max-height: 360px;
    margin-bottom: auto;
    padding-right: 20px;
    overflow: auto;

    line-height: 1.46;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track-piece {
      border-radius: 4px;
      background-color: #f7f4ff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #dcdcdc;
    }
  }

  .module.hasButton {
    .text {
      max-height: 233px;
    }
  }

  .button {
    width: 360px;

    font-size: 16px;
  }
}
