.title {
  margin-bottom: 10px;

  font-size: 20px;
  line-height: 1.5;
  text-align: center;

  color: #fff;
}

.steps {
  margin-bottom: 23px;

  font-size: 18px;
  line-height: 1.67;
  text-align: center;

  color: #fff;
}

.quiz {
  margin-bottom: 23px;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.arrival {
  margin-bottom: 27px;

  border: none;
  background-color: #fff;
}

.departure {
  border: none;
  background-color: #fff;
}

.adults {
  margin-bottom: 27px;

  border: none;
  background-color: #fff;
}

.babiesSelect {
  border: none;
  background-color: #fff;
}

.babies {
  margin-top: 27px;

  border: none;
  background-color: #fff;
}

.name {
  margin-bottom: 27px;

  border: none;
  background-color: #fff;
}

.phone {
  margin-bottom: 27px;

  border: none;
  background-color: #fff;
}

.policy {
  p {
    color: #fff;
  }
}

@media (min-width: 768px) {
  .title {
    margin-bottom: 43px;

    font-size: 22px;
  }

  .step {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .arrival {
    width: calc(50% - 10px);
    margin-right: 10px;
    margin-bottom: 0;
  }

  .departure {
    width: calc(50% - 10px);
    margin-left: 10px;
  }

  .adults {
    width: calc(50% - 10px);
    margin-right: 10px;
    margin-bottom: 0;
  }

  .babiesSelect {
    width: calc(50% - 10px);
    margin-left: 10px;
  }

  .babies {
    width: calc(50% - 10px);
  }

  .name {
    width: calc(50% - 10px);
    margin-right: 10px;
    margin-bottom: 0;
  }

  .phone {
    width: calc(50% - 10px);
    margin-left: 10px;
    margin-bottom: 0;
  }

  .policy {
    margin-top: 20px;
  }
}

@media (min-width: 1200px) {
  .title {
    margin-bottom: 10px;

    line-height: 1.36;
    text-align: left;
  }

  .steps {
    text-align: left;
  }

  .step {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .arrival {
    width: 100%;
    margin-right: 0;
    margin-bottom: 27px;
  }

  .departure {
    width: 100%;
    margin-left: 0;
  }

  .adults {
    width: 100%;
    margin-right: 0;
    margin-bottom: 27px;
  }

  .babiesSelect {
    width: 100%;
    margin-left: 0;
  }

  .babies {
    width: 100%;
  }

  .name {
    width: 100%;
    margin-right: 0;
    margin-bottom: 27px;
  }

  .phone {
    width: 100%;
    margin-left: 0;
    margin-bottom: 27px;
  }
}
