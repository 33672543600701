.module {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 442px;
  max-width: 100%;
  padding: 50px 20px 46px;

  border-radius: 10px;
  background-color: #fff;
}

.close {
  position: absolute;
  top: 14px;
  right: 7px;

  width: 40px;
  height: 40px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(45deg);

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(-45deg);

    content: '';
  }
}

.title {
  align-self: flex-start;
  margin-bottom: 25px;

  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;

  color: transparent;
  background-image: linear-gradient(135.61deg, #6a67ce 30.59%, #1aafd0 73.14%);
  background-clip: text;
}

.content {
  font-size: 16px;
  line-height: 1.5;

  p:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .module {
    width: 700px;
  }
}
