.module {
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 34px;
  padding-right: 18px;

  border: 1px solid rgba(116, 145, 165, 0.23);
  border-radius: 70px;
  cursor: pointer;
}

.name {
  margin-right: 8px;

  color: #42426a;
}

.value {
}

.icon {
  margin-left: auto;
}
