.module {
  overflow: hidden;
}

.wrapper {
  display: flex;

  transition: 0.2s;

  & > * {
    flex: 1 0 100%;
  }
}
