.rooms {
  position: relative;

  #rooms {
    position: absolute;
    top: -66px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 39px;
    padding-bottom: 71px;
  }

  &__title {
    align-self: center;

    font-size: 28px;
    font-weight: 700;
    line-height: 2.5;
    text-align: center;

    color: transparent;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    background-clip: text;
  }

  &__block {
    &:not(:last-child) {
      margin-bottom: 52px;
    }
  }

  &__subtitle {
    margin-bottom: 28px;

    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;

    color: map-get($colors, 'main');
  }

  &__slider {
    margin-bottom: 54px;

    .swiper-container {
      overflow: visible;
    }

    .swiper-slide {
      &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: rgba(255, 255, 255, 0.74);

        content: '';
      }

      &-active {
        &::before {
          display: none;
        }
      }
    }
  }

  &__slide {
    border-radius: 5px;
    box-shadow: 0px 15px 21px rgba(66, 66, 106, 0.1);
  }

  &__promotion {
    position: absolute;
    z-index: 5;
    top: 20px;
    right: 0;

    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;

    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #fff;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);
    box-shadow: 3px 5px 10px 1px rgba(0, 0, 0, 0.25);

    &--down {
      margin-top: 50px;
    }
  }

  &__hit {
    position: absolute;
    z-index: 5;
    top: 20px;
    right: 0;

    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;

    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #fff;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    box-shadow: 3px 5px 10px 1px rgba(0, 0, 0, 0.25);
  }

  &__wrapper {
    position: relative;

    overflow: hidden;
  }

  &__images {
    display: flex;

    transition: 0.2s;
  }

  &__image {
    position: relative;

    flex: 1 0 100%;
    padding-bottom: 80%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &__inner-nav {
  }

  &__inner-prev {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 9px;

    width: 46px;
    height: 46px;

    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 17px;
      left: 20px;

      width: 11px;
      height: 11px;

      border-left: 1px solid map-get($colors, 'main');
      border-bottom: 1px solid map-get($colors, 'main');
      transform: rotate(45deg);

      content: '';
    }
  }

  &__inner-next {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 9px;

    width: 46px;
    height: 46px;

    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 17px;
      right: 20px;

      width: 11px;
      height: 11px;

      border-right: 1px solid map-get($colors, 'main');
      border-top: 1px solid map-get($colors, 'main');
      transform: rotate(45deg);

      content: '';
    }
  }

  &__inner-pagination {
    position: absolute;
    left: 50%;
    bottom: 22px;

    display: flex;

    transform: translateX(-50%);
  }

  &__inner-pagination-item {
    width: 7px;
    height: 7px;

    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);

    &:not(:last-child) {
      margin-right: 7px;
    }

    &--active {
      background-image: linear-gradient(
        135.61deg,
        #6a67ce 30.59%,
        #1aafd0 73.14%
      );
      box-shadow: 0px 8px 15px rgba(249, 159, 64, 0.24);
    }
  }

  &__magnifier {
    display: none;
  }

  &__body {
    padding: 27px 20px 26px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
  }

  &__name {
    margin-bottom: 21px;
    overflow: hidden;

    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: map-get($colors, 'main');
  }

  &__seats {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    span {
      font-size: 18px;
      line-height: 1.22;

      color: map-get($colors, 'main');
    }
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 51px;
    margin-bottom: 6px;

    font-size: 13px;
    line-height: 1.38;

    color: map-get($colors, 'main');
  }

  &__detail {
    position: relative;

    display: inline-block;
    margin-bottom: 15px;

    font-size: 13px;
    line-height: 1.5;

    color: transparent;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    background-clip: text;
    cursor: pointer;

    &::before {
      position: absolute;
      left: 0;
      bottom: 0;

      width: 100%;
      height: 1px;

      background-image: linear-gradient(
        135.61deg,
        #6a67ce 30.59%,
        #1aafd0 73.14%
      );

      content: '';
    }
  }

  &__properties {
    display: flex;
    flex-wrap: wrap;
    height: 56px;
    margin: 0 -3.75%;
  }

  &__property {
    position: relative;

    width: 5%;
    height: 18px;
    margin-bottom: 10px;
    margin-left: 3.75%;
    margin-right: 3.75%;
    object-fit: contain;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    span {
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: calc(100% + 10px);

      padding: 10px;

      font-size: 13px;
      white-space: nowrap;

      border-radius: 3px;
      color: map-get($colors, 'main');
      background-color: #fff;
      box-shadow: 0px 15px 21px rgba(66, 66, 106, 0.1);
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;

      transition: 0.2s;
    }

    &:nth-child(1),
    &:nth-child(9) {
      span {
        left: -10px;
        transform: translateX(0);
      }
    }

    &:nth-child(8),
    &:nth-child(16) {
      span {
        left: auto;
        right: -10px;
        transform: translateX(0);
      }
    }

    &:hover {
      span {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__prices {
    display: flex;
    align-items: baseline;
    margin-bottom: 15px;

    del {
      margin-right: 8px;

      color: #c6c6c6;
    }

    strong {
      margin-right: 8px;

      font-size: 18px;
      font-weight: 700;

      color: transparent;
      background-image: linear-gradient(
        135.61deg,
        #6a67ce 30.59%,
        #1aafd0 73.14%
      );
      background-clip: text;
    }

    span {
      font-size: 16px;

      color: map-get($colors, 'main');
    }
  }

  &__buttons {
    display: flex;
  }

  &__calculation {
    flex-grow: 1;
    height: 60px;
    margin-right: 20px;

    font-size: 15px;
    font-weight: 500;

    border: 1px solid map-get($colors, 'second');
    border-radius: 60px;
    color: map-get($colors, 'second');

    transition: 0.2s;

    &:hover {
      border: none;
      color: #fff;
      background-image: linear-gradient(
        113.2deg,
        #fd636b 10.25%,
        #ffd200 98.71%
      );
    }
  }

  &__booking {
    width: 60px;
    height: 60px;

    border-radius: 50%;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

    transition: 0.2s;

    span {
      display: none;
    }

    &:hover {
      box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
    }
  }

  &__nav {
    display: none;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    width: 100%;

    .swiper-pagination-bullet {
      width: 11px;
      height: 11px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 11px;
      }

      &-active {
        background-image: linear-gradient(
          135.61deg,
          #6a67ce 30.59%,
          #1aafd0 73.14%
        );
        box-shadow: 0px 8px 15px rgba(249, 159, 64, 0.24);
      }
    }
  }
}

@media (min-width: 768px) {
  .rooms {
    &__content {
      padding-bottom: 52px;
    }

    &__slider {
      .swiper-slide-active {
        & + .swiper-slide {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .rooms {
    #rooms {
      top: -91px;
    }

    &__content {
      padding-top: 88px;
      padding-bottom: 139px;
    }

    &__title {
      margin-bottom: 16px;

      font-size: 45px;
      line-height: 1.56;
    }

    &__subtitle {
      margin-bottom: 40px;

      font-size: 26px;
    }

    &__slider {
      position: relative;

      .swiper-slide-active {
        & + .swiper-slide + .swiper-slide {
          &::before {
            display: none;
          }
        }
      }
    }

    &__nav {
      display: flex;
    }

    &__prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: -36px;

      width: 72px;
      height: 72px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      transform: translateY(-50%);

      transition: 0.2s;

      &.swiper-button-disabled {
        display: none;
      }

      &::before {
        position: absolute;
        top: 29px;
        left: 31px;

        width: 12px;
        height: 12px;

        border-left: 1px solid map-get($colors, 'main');
        border-bottom: 1px solid map-get($colors, 'main');
        border-radius: 1px;
        transform: rotate(45deg);

        content: '';
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    &__next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: -36px;

      width: 72px;
      height: 72px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      transform: translateY(-50%);

      transition: 0.2s;

      &.swiper-button-disabled {
        display: none;
      }

      &::before {
        position: absolute;
        top: 29px;
        right: 31px;

        width: 12px;
        height: 12px;

        border-right: 1px solid map-get($colors, 'main');
        border-bottom: 1px solid map-get($colors, 'smain');
        border-radius: 1px;
        transform: rotate(-45deg);

        content: '';
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    &__wrapper {
      &:hover {
        .rooms__inner-nav {
          visibility: visible;
          opacity: 1;
        }

        .rooms__inner-pagination {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &__inner-nav {
      visibility: hidden;
      opacity: 0;

      transition: 0.2s;
    }

    &__inner-prev {
      transition: 0.2s;

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    &__inner-next {
      transition: 0.2s;

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    &__inner-pagination {
      visibility: hidden;
      opacity: 0;

      transition: 0.2s;
    }

    &__calculation {
      flex-grow: initial;
      width: calc(50% - 5px);
      height: 50px;
      margin-right: 5px;

      font-size: 13px;
    }

    &__booking {
      width: calc(50% - 5px);
      height: 50px;
      margin-left: 5px;

      border-radius: 60px;

      img {
        display: none;
      }

      span {
        display: initial;

        font-size: 13px;
        font-weight: 500;

        color: #fff;
      }
    }
  }
}
