.module {
  display: flex;
}

.prev {
  height: 60px;
  width: calc(50% - 7px);
  margin-right: 7px;

  font-size: 15px;
  font-weight: 500;

  border: 1px solid #f99f40;
  border-radius: 70px;
  color: #f99f40;

  transition: 0.2s;

  &:hover {
    border: none;
    color: #fff;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);
  }
}

.next {
  flex-grow: 1;
  height: 60px;

  font-size: 15px;
  font-weight: 500;

  border-radius: 70px;
  color: #fff;
  background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

  transition: 0.2s;

  &:hover {
    box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
  }
}

.confirm {
  flex-grow: 1;
  height: 60px;

  font-size: 15px;
  font-weight: 500;

  border-radius: 70px;
  color: #fff;
  background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

  transition: 0.2s;

  &:hover {
    box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
  }
}

@media (min-width: 1200px) {
  .prev {
    font-size: 16px;
  }

  .next {
    font-size: 16px;
  }

  .confirm {
    font-size: 16px;
  }
}
