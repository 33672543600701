.reviews {
  position: relative;

  #reviews {
    position: absolute;
    top: -66px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 33px;
    padding-bottom: 74px;
  }

  &__title {
    align-self: center;
    margin-bottom: 20px;

    font-size: 28px;
    font-weight: 700;
    line-height: 2.5;
    text-align: center;

    color: transparent;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    background-clip: text;
  }

  &__slider {
    margin-bottom: 34px;

    .swiper-container {
      overflow: visible;
    }

    .swiper-slide {
      &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: rgba(255, 255, 255, 0.74);

        content: '';
      }

      &-active {
        &::before {
          display: none;
        }
      }
    }
  }

  &__slide {
    display: flex;
    flex-direction: column;
    padding: 41px 24px 37px 34px;

    border-radius: 5px;
    background-color: #fff;

    background-repeat: no-repeat;
    background-position: bottom 30px right 40px;

    &--cut {
      .reviews__text {
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .reviews__button {
        visibility: visible;
      }
    }
  }

  &__name {
    margin-bottom: 12px;

    font-size: 18px;
    font-weight: 700;
    line-height: 1.22;

    color: map-get($colors, 'main');
  }

  &__grade {
    align-self: flex-start;
    margin-bottom: 10px;

    font-size: 16px;
    font-weight: 600;
    line-height: 1.57;

    color: transparent;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    background-clip: text;
  }

  &__text {
    min-height: 200px;
    margin-bottom: 26px;
    overflow: hidden;

    font-size: 13px;
    font-weight: 300;
    line-height: 1.54;

    color: map-get($colors, 'main');
  }

  &__button {
    align-self: flex-start;

    font-size: 15px;
    font-weight: 300;
    line-height: 1.47;
    text-decoration: underline;

    visibility: hidden;

    color: map-get($colors, 'second');
  }

  &__pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 53px;

    .swiper-pagination-bullet {
      width: 11px;
      height: 11px;
      margin-bottom: 7px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 11px;
      }

      &-active {
        background-image: linear-gradient(
          135.61deg,
          #6a67ce 30.59%,
          #1aafd0 73.14%
        );
        box-shadow: 0px 8px 15px rgba(249, 159, 64, 0.24);
      }
    }
  }

  &__add {
    display: block;
    width: 240px;
    height: 60px;
    margin: 0 auto;

    font-size: 15px;

    border-radius: 70px;
    color: #fff;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

    transition: 0.2s;

    &:hover {
      box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
    }
  }
}

@media (min-width: 768px) {
  .reviews {
    &__slider {
      .swiper-slide-active {
        & + .swiper-slide {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .reviews {
    #reviews {
      top: -91px;
    }

    &__content {
      padding-top: 109px;
      padding-bottom: 91px;
    }

    &__title {
      margin-bottom: 48px;

      font-size: 45px;
      line-height: 1.56;
    }

    &__slider {
      position: relative;
      margin-bottom: 53px;

      .swiper-slide-active {
        & + .swiper-slide + .swiper-slide {
          &::before {
            display: none;
          }
        }
      }
    }

    &__nav {
      display: flex;
    }

    &__prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: -36px;

      width: 72px;
      height: 72px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      transform: translateY(-50%);

      transition: 0.2s;

      &::before {
        position: absolute;
        top: 29px;
        left: 31px;

        width: 12px;
        height: 12px;

        border-left: 1px solid map-get($colors, 'main');
        border-bottom: 1px solid map-get($colors, 'main');
        border-radius: 1px;
        transform: rotate(45deg);

        content: '';
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    &__next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: -36px;

      width: 72px;
      height: 72px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      transform: translateY(-50%);

      transition: 0.2s;

      &::before {
        position: absolute;
        top: 29px;
        right: 31px;

        width: 12px;
        height: 12px;

        border-right: 1px solid map-get($colors, 'main');
        border-bottom: 1px solid map-get($colors, 'smain');
        border-radius: 1px;
        transform: rotate(-45deg);

        content: '';
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    &__add {
      font-size: 16px;
    }
  }
}
