.module {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 442px;
  max-width: 100%;
  padding: 33px 25px 30px;

  border-radius: 10px;
  background-color: #fff;
}

.close {
  position: absolute;
  top: 14px;
  right: 7px;

  width: 40px;
  height: 40px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(45deg);

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(-45deg);

    content: '';
  }
}

.title {
  align-self: flex-start;
  margin-bottom: 25px;

  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;

  color: transparent;
  background-image: linear-gradient(135.61deg, #6a67ce 30.59%, #1aafd0 73.14%);
  background-clip: text;
}

.first {
  margin-bottom: 25px;
}

.seats {
  margin-bottom: 36px;

  font-size: 18px;

  color: #42426a;
}

.furniture {
  display: flex;
  margin-bottom: 18px;

  p {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  img {
    margin-right: 3px;
  }
}

.text {
  margin-bottom: 20px;
  max-height: 102px;
  overflow: auto;

  font-size: 13px;
  font-weight: 300;
  line-height: 1.38;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track-piece {
    border-radius: 4px;
    background-color: #f7f4ff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #dcdcdc;
  }
}

.properties {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -3.75%;
  margin-bottom: 10px;
}

.property {
  position: relative;

  width: 5%;
  height: 18px;
  margin-bottom: 10px;
  margin-left: 3.75%;
  margin-right: 3.75%;
  object-fit: contain;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  span {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: calc(100% + 10px);

    padding: 10px;

    font-size: 13px;
    white-space: nowrap;

    border-radius: 3px;
    color: #42426a;
    background-color: #fff;
    box-shadow: 0px 15px 21px rgba(66, 66, 106, 0.1);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;

    transition: 0.2s;
  }

  &:nth-child(1),
  &:nth-child(9) {
    span {
      left: -10px;
      transform: translateX(0);
    }
  }

  &:nth-child(8),
  &:nth-child(16) {
    span {
      left: auto;
      right: -10px;
      transform: translateX(0);
    }
  }

  &:hover {
    span {
      visibility: visible;
      opacity: 1;
    }
  }
}

.prices {
  display: flex;
  align-items: baseline;
  margin-bottom: 31px;

  del {
    margin-right: 8px;

    color: #c6c6c6;
  }

  strong {
    margin-right: 8px;

    font-size: 18px;
    font-weight: 700;

    color: transparent;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    background-clip: text;
  }

  span {
    font-size: 16px;

    color: #42426a;
  }
}

.quizTitle {
  margin-bottom: 10px;

  font-size: 17px;
  line-height: 1.76;

  color: #42426a;
}

.steps {
  margin-bottom: 14px;

  font-size: 15px;
  line-height: 2;

  color: #42426a;
}

.quiz {
  margin-bottom: 23px;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.arrival {
  margin-bottom: 27px;
}

.adults {
  margin-bottom: 27px;
}

.babies {
  margin-top: 27px;
}

.name {
  margin-bottom: 27px;
}

.phone {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .module {
    width: 720px;
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 60px;
  }
}

@media (min-width: 1200px) {
  .module {
    width: 1140px;
    padding-top: 50px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 60px;
  }

  .title {
    margin-bottom: 40px;

    font-size: 28px;
  }

  .content {
    display: flex;
  }

  .first {
    width: calc(100% - 370px);
    margin-bottom: 0;
    padding-right: 50px;
  }

  .second {
    width: 370px;
  }
}
