.footer {
  &__content {
    padding-top: 23px;
    padding-bottom: 10px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 9px;

    button {
      font-size: 12px;
      font-weight: 300;
      line-height: 2.5;
      text-transform: uppercase;
      text-decoration: underline;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &__bottom {
    p {
      font-size: 12px;
      font-weight: 300;
      line-height: 2.5;
      text-align: center;
    }
  }
}

@media (min-width: 768px) {
  .footer {
    &__content {
      padding-top: 14px;
      padding-bottom: 13px;
    }
  }
}

@media (min-width: 1200px) {
  .footer {
    &__content {
      display: flex;
      padding-bottom: 16px;
    }

    &__buttons {
      flex-direction: row;
      margin-right: auto;
      margin-bottom: 0;

      button {
        &:not(:last-child) {
          margin-right: 32px;
          margin-bottom: 0;
        }
      }
    }
  }
}
