.contacts {
  position: relative;

  #contacts {
    position: absolute;
    top: -66px;
  }

  &__content {
    padding-top: 63px;
    padding-bottom: 39px;
  }

  &__title {
    align-self: flex-start;
    margin-bottom: 13px;

    font-size: 28px;
    font-weight: 700;
    line-height: 2.5;

    color: transparent;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    background-clip: text;
  }

  &__textual {
    display: flex;
    flex-direction: column;
    margin-bottom: 49px;
  }

  &__list {
    margin-bottom: 35px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__line {
    display: flex;
    align-items: center;
    margin-bottom: 3px;

    strong {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      margin-right: 12px;

      border-radius: 50%;
      background-image: linear-gradient(180deg, #6a67ce 0%, #1aafd0 100%);
    }

    img {
      width: 13px;
    }

    span {
      font-size: 15px;
      font-weight: 300;
      line-height: 1.67;

      color: map-get($colors, 'main');
    }
  }

  &__phone {
    display: block;
    margin-left: 38px;

    font-size: 26px;
    font-weight: 700;
    line-height: 1.15;

    color: map-get($colors, 'main');
  }

  &__email {
    display: block;
    margin-left: 38px;

    font-size: 18px;
    line-height: 1.39;

    color: map-get($colors, 'main');
  }

  &__address {
    display: block;
    margin-left: 38px;

    font-size: 18px;
    line-height: 1.39;

    color: map-get($colors, 'main');
  }

  &__social {
    display: flex;
    align-items: center;
    margin-left: 38px;
    padding-top: 10px;

    a {
      width: 20px;

      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }

  &__button {
    display: block;
    width: 260px;
    height: 60px;
    margin: 0 auto;

    font-size: 15px;

    border-radius: 70px;
    color: #fff;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

    transition: 0.2s;

    &:hover {
      box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
    }
  }

  &__map {
    position: relative;
    padding-bottom: 88%;
  }

  #map {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
}

@media (min-width: 768px) {
  .contacts {
    #contacts {
      top: -91px;
    }

    &__content {
      display: flex;
      padding-top: 45px;
      padding-bottom: 78px;
    }

    &__textual {
      width: 50%;
      margin-bottom: 0;
    }

    &__button {
      margin: 0;
      width: 320px;
    }

    &__map {
      width: 50%;
      padding-bottom: 0;
    }
  }
}

@media (min-width: 1200px) {
  .contacts {
    &__content {
      justify-content: space-between;
      padding-top: 95px;
      padding-bottom: 116px;
    }

    &__textual {
      width: auto;
    }

    &__title {
      margin-bottom: 35px;

      font-size: 45px;
      line-height: 1.56;
    }

    &__map {
      width: 700px;
    }

    &__button {
      font-size: 16px;
    }
  }
}
