.question {
  position: relative;

  background-image: linear-gradient(90deg, #6967ce 17.39%, #1cadd0 88.15%);

  &__bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;

    opacity: 0.15;
  }

  &__content {
    position: relative;

    padding-top: 56px;
  }

  &__title {
    margin-bottom: 13px;

    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    text-align: center;

    color: #fff;
  }

  &__text {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;

    font-size: 18px;
    line-height: 1.61;
    text-align: center;

    color: #fff;
  }

  &__button {
    display: block;
    width: 240px;
    height: 60px;
    margin: 0 auto;
    margin-bottom: 36px;

    font-size: 15px;

    color: #fff;
    border-radius: 70px;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

    transition: 0.2s;

    &:hover {
      box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
    }
  }

  &__image {
    display: flex;
    width: 285px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .question {
    &__content {
      padding-top: 143px;
      padding-bottom: 143px;
    }

    &__title {
      text-align: left;
    }

    &__text {
      max-width: 400px;
      margin: 0;
      margin-bottom: 13px;
      text-align: left;
    }

    &__button {
      margin: 0;
    }

    &__image {
      position: absolute;
      right: 33px;
      bottom: 0;
    }
  }
}

@media (min-width: 1200px) {
  .question {
    &__content {
      padding-top: 141px;
      padding-bottom: 127px;
    }

    &__title {
      margin-bottom: 7px;

      font-size: 45px;
    }

    &__text {
      max-width: 485px;
      margin-bottom: 15px;

      font-size: 20px;
      line-height: 1.75;
      text-transform: uppercase;
    }

    &__image {
      width: 415px;
    }

    &__button {
      font-size: 16px;
    }
  }
}
