.services {
  position: relative;

  background-image: linear-gradient(135.61deg, #6a67ce 30.59%, #1aafd0 73.14%);

  #services {
    position: absolute;
    top: -66px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;

    opacity: 0.15;
  }

  &__content {
    position: relative;

    padding-top: 48px;
    padding-bottom: 49px;
  }

  &__title {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 23px;

    font-size: 28px;
    font-weight: 700;
    line-height: 1.48;
    text-align: center;

    color: #fff;
  }

  &__slider {
    margin-bottom: 39px;

    .swiper-container {
      overflow: visible;
    }
  }

  &__slide {
  }

  &__image {
    position: relative;

    padding-bottom: 105%;

    cursor: pointer;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  &__body {
    padding: 22px 20px 25px;

    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #fff;
  }

  &__name {
    margin-bottom: 10px;

    font-size: 19px;
    font-weight: 700;
    line-height: 1.21;
    text-align: center;

    color: transparent;
    background-image: linear-gradient(180deg, #6967ce 0%, #1cadd0 100%);
    background-clip: text;
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    margin-bottom: 22px;

    font-size: 13px;
    font-weight: 300;
    line-height: 1.38;
    text-align: center;
  }

  &__buttons {
    display: flex;
  }

  &__detail {
    flex-grow: 1;
    height: 60px;
    margin-right: 20px;

    font-size: 15px;
    font-weight: 500;

    border: 1px solid map-get($colors, 'second');
    border-radius: 60px;
    color: map-get($colors, 'second');

    transition: 0.2s;

    &:hover {
      border: none;
      color: #fff;
      background-image: linear-gradient(
        113.2deg,
        #fd636b 10.25%,
        #ffd200 98.71%
      );
    }
  }

  &__booking {
    width: 60px;
    height: 60px;

    border-radius: 50%;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

    transition: 0.2s;

    span {
      display: none;
    }

    &:hover {
      box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
    }
  }

  &__nav {
    display: none;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    width: 100%;

    .swiper-pagination-bullet {
      width: 11px;
      height: 11px;

      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 11px;
      }

      &-active {
        background-image: linear-gradient(
          135.61deg,
          #6a67ce 30.59%,
          #1aafd0 73.14%
        );
        box-shadow: 0px 8px 15px rgba(249, 159, 64, 0.24);
      }
    }
  }
}

@media (min-width: 768px) {
  .services {
  }
}

@media (min-width: 1200px) {
  .services {
    #services {
      top: -91px;
    }

    &__content {
      padding-top: 99px;
      padding-bottom: 128px;
    }

    &__title {
      max-width: none;
      margin-bottom: 47px;

      font-size: 45px;
      line-height: 1.56;
    }

    &__slider {
      margin-bottom: 0;

      .swiper-container {
        overflow: hidden;
      }
    }

    &__image {
      padding-bottom: 90%;
    }

    &__text {
      margin-bottom: 25px;
    }

    &__detail {
      flex-grow: initial;
      width: calc(50% - 5px);
      height: 50px;
      margin-right: 5px;

      font-size: 13px;
    }

    &__booking {
      width: calc(50% - 5px);
      height: 50px;
      margin-left: 5px;

      border-radius: 60px;

      img {
        display: none;
      }

      span {
        display: initial;

        font-size: 13px;
        font-weight: 500;

        color: #fff;
      }
    }

    &__pagination {
      display: none;
    }
  }
}
