.module {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 442px;
  max-width: 100%;
  padding: 50px 20px 60px;

  border-radius: 10px;
  background-color: #fff;
}

.close {
  position: absolute;
  top: 14px;
  right: 7px;

  width: 40px;
  height: 40px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(45deg);

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(-45deg);

    content: '';
  }
}

.title {
  align-self: flex-start;
  margin-bottom: 25px;

  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;

  color: transparent;
  background-image: linear-gradient(135.61deg, #6a67ce 30.59%, #1aafd0 73.14%);
  background-clip: text;
}

.date {
  margin-bottom: 14px;
}

.timeBlock {
  position: relative;

  margin-bottom: 14px;

  & > img {
    position: absolute;
    top: 50%;
    right: 18px;

    transform: translateY(-50%);
  }
}

.time {
  &::before {
    display: none;
  }
}

.name {
  margin-bottom: 14px;
}

.phone {
  margin-bottom: 39px;
}

.policy {
  margin-bottom: 31px;
}

.confirm {
  width: 100%;
  height: 60px;

  font-size: 15px;
  font-weight: 500;

  border-radius: 70px;
  color: #fff;
  background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

  transition: 0.2s;

  &:hover {
    box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
  }
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .confirm {
    font-size: 16px;
  }
}
