.advantages {
  position: relative;

  #advantages {
    position: absolute;
    top: -66px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 46px;
  }

  &__title {
    align-self: center;
    margin-bottom: 15px;

    font-size: 28px;
    font-weight: 700;
    line-height: 2.5;
    text-align: center;

    color: transparent;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    background-clip: text;
  }

  &__slider {
    margin-bottom: 39px;

    .swiper-container {
      overflow: visible;
    }

    .swiper-slide {
      &::before {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: rgba(255, 255, 255, 0.74);

        content: '';
      }

      &-active {
        &::before {
          display: none;
        }
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    border-radius: 15px;
    background-color: #fff;
    cursor: pointer;
  }

  &__image {
    position: relative;

    padding-bottom: 127%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 5px;
    }
  }

  &__name {
    // text-align: center;

    // color: #fff;

    // display: block;
    // width: auto;
    // height: 56px;
    padding: 0 20px;
    overflow: hidden;

    font-size: 18px;
    font-weight: 700;
    line-height: 56px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: transparent;
    background-image: linear-gradient(180deg, #6967ce 0%, #1cadd0 100%);
    background-clip: text;
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    font-size: 0;
  }

  &__nav {
    display: none;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    width: 100%;

    .swiper-pagination-bullet {
      width: 11px;
      height: 11px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 11px;
      }

      &-active {
        background-image: linear-gradient(
          135.61deg,
          #6a67ce 30.59%,
          #1aafd0 73.14%
        );
        box-shadow: 0px 8px 15px rgba(249, 159, 64, 0.24);
      }
    }
  }
}

@media (min-width: 768px) {
  .advantages {
    &__content {
      padding-top: 41px;
    }

    &__slider {
      .swiper-slide-active {
        & + .swiper-slide {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .advantages {
    $p: &;

    #advantages {
      top: -91px;
    }

    &__content {
      padding-top: 50px;
    }

    &__title {
      margin-bottom: 50px;

      font-size: 45px;
      line-height: 1.56;
    }

    &__slider {
      position: relative;
      margin-bottom: 84px;

      .swiper-slide-active {
        & + .swiper-slide + .swiper-slide,
        & + .swiper-slide + .swiper-slide + .swiper-slide {
          &::before {
            display: none;
          }
        }
      }
    }

    &__slide {
      display: flex;
      flex-direction: column;

      border-radius: 10px;
      background-color: #fff;

      &:hover {
        #{$p}__image {
          &::before {
            opacity: 1;
          }
        }

        #{$p}__button {
          opacity: 1;
        }
      }
    }

    &__image {
      padding-bottom: 123%;

      &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0;

        transition: 0.2s;

        content: '';
      }
    }

    &__name {
      position: static;

      display: block;
      width: auto;
      height: 56px;
      padding: 0 20px;
      overflow: hidden;

      line-height: 56px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;

      color: transparent;
      background-image: linear-gradient(180deg, #6967ce 0%, #1cadd0 100%);
      background-clip: text;
    }

    &__button {
      top: auto;
      z-index: 1;
      left: 50%;
      bottom: 86px;

      width: 189px;
      height: 47px;

      font-size: 16px;
      font-weight: 300;

      border-radius: 70px;
      color: #fff;
      background-image: linear-gradient(
        113.2deg,
        #fd636b 10.25%,
        #ffd200 98.71%
      );
      transform: translateX(-50%);
      opacity: 0;

      transition: 0.2s;
    }

    &__nav {
      display: flex;
    }

    &__prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: -36px;

      width: 72px;
      height: 72px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      transform: translateY(-50%);

      transition: 0.2s;

      &::before {
        position: absolute;
        top: 29px;
        left: 31px;

        width: 12px;
        height: 12px;

        border-left: 1px solid map-get($colors, 'main');
        border-bottom: 1px solid map-get($colors, 'main');
        border-radius: 1px;
        transform: rotate(45deg);

        content: '';
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    &__next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: -36px;

      width: 72px;
      height: 72px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      transform: translateY(-50%);

      transition: 0.2s;

      &::before {
        position: absolute;
        top: 29px;
        right: 31px;

        width: 12px;
        height: 12px;

        border-right: 1px solid map-get($colors, 'main');
        border-bottom: 1px solid map-get($colors, 'smain');
        border-radius: 1px;
        transform: rotate(-45deg);

        content: '';
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }
  }
}
