.module {
  display: flex;
  align-items: center;
}

.checkbox {
  flex-shrink: 0;
  margin-right: 19px;
}

.text {
  font-size: 14px;
  line-height: 1.2;

  color: #42426a;
}

.button {
  text-decoration: underline;

  cursor: pointer;
}
