.module {
  position: relative;

  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 34px;

  border: 1px solid rgba(116, 145, 165, 0.23);
  border-radius: 70px;

  cursor: pointer;

  &::before {
    position: absolute;
    top: 24px;
    right: 24px;

    width: 7px;
    height: 7px;

    border-left: 1px solid rgb(197, 196, 240);
    border-bottom: 1px solid rgb(197, 196, 240);

    transform: rotate(-45deg);

    content: '';
  }
}

.name {
  margin-right: 8px;

  color: #42426a;
}

.values {
}
