.module {
  position: relative;

  width: 32px;
  height: 32px;

  border: 1px solid rgba(116, 145, 165, 0.23);
  border-radius: 4px;
  background-color: #fff;
}

.checkbox {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;

  cursor: pointer;
  appearance: none;

  &:checked + .checkMark {
    position: absolute;
    top: 10px;
    left: 8px;

    width: 14px;
    height: 8px;

    border-left: 2px solid #f99f40;
    border-bottom: 2px solid #f99f40;
    transform: rotate(-45deg);
  }
}
