.module {
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 150px;
  padding: 20px 34px;

  border: 1px solid rgba(116, 145, 165, 0.23);
  border-radius: 25px;
  outline: none;

  &::placeholder {
    color: #42426a;
  }
}
