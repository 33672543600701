.screen {
  background-size: auto 160%;
  background-position: 55% 100%;
  background-repeat: no-repeat;

  &__content {
    display: flex;
    flex-direction: column;
    height: 626px;
    padding-top: 151px;
  }

  &__title {
    margin-bottom: 10px;

    font-size: 18px;
    text-transform: uppercase;

    color: #fff;
  }

  &__name {
    margin-bottom: 10px;

    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;

    color: #fff;
  }

  &__text {
    max-width: 270px;
    margin-bottom: 26px;

    font-size: 16px;
    line-height: 1.25;

    color: #fff;

    strong {
      font-weight: 700;

      // color: map-get($colors, 'second');
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    order: 2;
    max-width: 283px;
    margin-bottom: 96px;
  }

  &__calculation {
    width: 210px;
    height: 60px;

    font-size: 16px;
    font-weight: 500;

    border-radius: 70px;
    color: #fff;
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      background-image: linear-gradient(
        113.2deg,
        #fd636b 10.25%,
        #ffd200 98.71%
      ) !important;
    }
  }

  &__booking {
    width: 60px;
    height: 60px;

    border-radius: 70px;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

    transition: 0.2s;

    span {
      display: none;
    }

    &:hover {
      box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
    }
  }

  &__offer {
    margin-bottom: 40px;

    font-size: 14px;
    font-weight: 700;

    color: #f99f40;
  }
}

@media (min-width: 768px) {
  .screen {
    background-size: auto 140%;

    &__content {
      padding-top: 135px;
    }

    &__title {
      font-size: 28px;
    }

    &__name {
      margin-bottom: 0;

      font-size: 38px;
      line-height: 1.21;
    }

    &__text {
      max-width: 500px;

      font-size: 21px;
    }

    &__buttons {
      justify-content: flex-start;
      max-width: none;
      margin-bottom: 20px;
    }

    &__calculation {
      width: 252px;
      margin-right: 17px;
    }

    &__booking {
      width: 252px;

      border-radius: 70px;

      img {
        display: none;
      }

      span {
        display: initial;

        font-size: 17px;
        font-weight: 500;

        color: #fff;
      }
    }

    &__offer {
      font-size: 16px;
      text-align: left;
    }
  }
}

@media (min-width: 1200px) {
  .screen {
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    &__content {
      display: flex;
      flex-direction: column;
      height: 1007px;
      padding-top: 397px;
    }

    &__title {
      margin-bottom: 5px;

      font-size: 40px;
      line-height: 1.225;
    }

    &__name {
      margin-bottom: 56px;

      font-size: 67px;
      line-height: 1.224;
    }

    &__text {
      order: 1;
      max-width: none;
      margin-bottom: 15px;

      font-size: 26px;

      strong {
        font-size: 34px;

        color: #fff;
      }
    }

    &__buttons {
      order: 0;
      margin-bottom: 88px;
    }

    &__calculation {
      width: 280px;
      height: 70px;
      margin-right: 20px;
    }

    &__booking {
      width: 280px;
      height: 70px;
    }

    &__offer {
      order: 1;

      font-size: 28px;
      font-weight: 700;

      color: #f99f40;
    }
  }
}
