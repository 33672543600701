.module {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 720px;
  max-width: 100%;
  padding: 50px 20px 60px;

  border-radius: 10px;
  background-color: #fff;
}

.close {
  position: absolute;
  top: 0;
  right: 0;

  width: 40px;
  height: 40px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(45deg);

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(-45deg);

    content: '';
  }
}

.icon {
  width: 50px;
  margin-bottom: 30px;
}

.title {
  margin-bottom: 10px;

  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  color: transparent;
  background-image: linear-gradient(135.61deg, #6a67ce 30.59%, #1aafd0 73.14%);
  background-clip: text;
}

.text {
  margin-bottom: 40px;

  font-size: 16px;
  text-align: center;

  color: #42426a;
}

.button {
  width: 230px;
  height: 50px;

  font-size: 15px;
  font-weight: 500;

  border-radius: 70px;
  color: #fff;
  background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

  transition: 0.2s;

  &:hover {
    box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 28px;
  }
}
