.location {
  $p: &;

  position: relative;

  #location {
    position: absolute;
    top: -66px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 64px;
    padding-bottom: 86px;
  }

  &__title {
    align-self: center;
    margin-bottom: 33px;

    font-size: 28px;
    font-weight: 700;
    line-height: 2.5;
    text-align: center;

    color: transparent;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    background-clip: text;
  }

  &__block {
    position: relative;

    display: flex;
    justify-content: space-between;
    // margin: 0 -10px;
  }

  &__center {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 55%;

    transform: translate(-50%, -50%);
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: 33%;

    #{$p}__item {
      &:first-child,
      &:last-child {
        align-self: flex-end;
      }
    }

    #{$p}__wrapper {
      left: -10%;
      padding-left: 120%;
      padding-right: 30%;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 33%;

    #{$p}__item {
      &:first-child,
      &:last-child {
        align-self: flex-start;
      }
    }

    #{$p}__wrapper {
      right: -10%;
      padding-left: 30%;
      padding-right: 120%;
    }
  }

  &__item {
    width: 53%;

    &:not(:last-child) {
      margin-bottom: 19%;
    }

    &--special {
      #{$p}__image {
        background-image: none;
        background-color: #fff;
      }

      &#{$p}__item--active {
        #{$p}__wrapper {
          background-image: linear-gradient(
            113.2deg,
            #fd636b 10.25%,
            #ffd200 98.71%
          );
        }

        #{$p}__name {
          color: #fff;
        }

        #{$p}__duration {
          span {
            color: #fff;

            &::before {
              background-image: none;
              background-color: #fff;
            }
          }
        }
      }
    }

    &--active {
      position: relative;
      z-index: 1;

      #{$p}__wrapper {
        position: absolute;
        top: -10%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 120%;

        border-radius: 70px;
        background-color: #fff;
      }

      #{$p}__name {
        width: max-content;
        margin-bottom: 2%;

        font-size: 16px;
        line-height: 1.25;
      }

      #{$p}__duration {
        display: flex;
        justify-content: center;
        align-items: center;
        width: max-content;

        strong {
          img {
            width: 18px;
            height: 18px;
            margin-right: 8px;

            &:last-child {
              display: none;
            }
          }
        }

        span {
          display: flex;
          align-items: center;

          font-size: 12px;

          color: map-get($colors, 'main');

          &::before {
            width: 3px;
            height: 3px;
            margin-right: 7px;

            border-radius: 50%;
            background-image: linear-gradient(
              113.2deg,
              #fd636b 10.25%,
              #ffd200 98.71%
            );

            content: '';
          }
        }
      }
    }
  }

  &__wrapper {
    display: none;
  }

  &__image {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 100%;

    border-radius: 50%;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

    img {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 60%;
      max-height: 50%;

      transform: translate(-50%, -50%);

      &:last-child {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .location {
    &__content {
      padding-top: 59px;
      padding-bottom: 59px;
    }

    &__title {
      margin-bottom: 39px;
    }
  }
}

@media (min-width: 1200px) {
  .location {
    $p: &;

    #location {
      top: -91px;
    }

    &__content {
      max-width: 500px;
      padding-top: 89px;
      padding-bottom: 114px;
    }

    &__title {
      margin-bottom: 64px;

      font-size: 45px;
      line-height: 1.56;
    }

    &__center {
      width: 330px;
    }

    &__left {
      width: 100px;

      #{$p}__wrapper {
        right: -10px;
        left: auto;

        padding-left: 0;
        padding-right: 70px;

        background-size: 0;
        background-position: right;
        background-repeat: no-repeat;

        transition: 0.6s;
      }
    }

    &__right {
      width: 100px;

      #{$p}__wrapper {
        left: -10px;

        padding-left: 70px;
        padding-right: 0;

        background-size: 0;
        background-repeat: no-repeat;

        transition: 0.6s;
      }
    }

    &__item {
      position: relative;
      width: 61px;

      &:not(:last-child) {
        margin-bottom: 59px;
      }

      &:hover {
        #{$p}__wrapper {
          background-image: linear-gradient(
            113.2deg,
            #fd636b 10.25%,
            #ffd200 98.71%
          );
          background-size: 100%;
        }

        #{$p}__name {
          color: #fff;
        }

        #{$p}__duration {
          strong {
            img {
              &:first-child {
                display: none;
              }

              &:last-child {
                display: initial;
              }
            }
          }

          span {
            color: #fff;

            &::before {
              background-image: none;
              background-color: #fff;
            }
          }
        }

        #{$p}__image {
          background-image: none;
          background-color: #fff;

          img {
            &:first-child {
              display: none;
            }

            &:last-child {
              display: initial;
            }
          }
        }
      }

      &--special {
        #{$p}__wrapper {
          background-image: linear-gradient(
            113.2deg,
            #fd636b 10.25%,
            #ffd200 98.71%
          );
          background-size: 100%;
        }

        #{$p}__name {
          color: #fff;
        }

        #{$p}__duration {
          img {
            width: 18px;
            height: 18px;
          }

          span {
            color: #fff;

            &::before {
              background-image: none;
              background-color: #fff;
            }
          }
        }

        &:hover {
          #{$p}__wrapper {
            // background-image: none;
            background-size: 0;
          }

          #{$p}__name {
            color: #000;
          }

          #{$p}__duration {
            span {
              color: map-get($colors, 'main');

              &::before {
                background-image: linear-gradient(
                  113.2deg,
                  #fd636b 10.25%,
                  #ffd200 98.71%
                );
              }
            }
          }

          #{$p}__image {
            background-image: linear-gradient(
              113.2deg,
              #fd636b 10.25%,
              #ffd200 98.71%
            );
          }
        }
      }
    }

    &__wrapper {
      position: absolute;
      top: -10px !important;
      bottom: -10px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 380px;
      height: auto !important;

      border-radius: 70px;
      background-color: #fff;
    }

    &__name {
      width: max-content;
      margin-bottom: 2%;

      font-size: 16px;
      line-height: 1.25;
    }

    &__duration {
      display: flex;
      justify-content: center;
      align-items: center;
      width: max-content;

      strong {
        img {
          width: 18px;
          height: 18px;
          margin-right: 8px;

          &:last-child {
            display: none;
          }
        }
      }

      span {
        display: flex;
        align-items: center;

        font-size: 12px;

        color: map-get($colors, 'main');

        &::before {
          width: 3px;
          height: 3px;
          margin-right: 7px;

          border-radius: 50%;
          background-image: linear-gradient(
            113.2deg,
            #fd636b 10.25%,
            #ffd200 98.71%
          );

          content: '';
        }
      }
    }
  }
}
