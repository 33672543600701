.about {
  position: relative;

  #about {
    position: absolute;
    top: -66px;
  }

  &__content {
    padding-top: 18px;
  }

  &__textual {
    display: flex;
    flex-direction: column;
    margin-bottom: 84px;
  }

  &__title {
    align-self: center;
    margin-bottom: 11px;

    font-size: 28px;
    font-weight: 700;
    line-height: 2.5;
    text-align: center;

    color: transparent;
    background-image: linear-gradient(
      135.61deg,
      #6a67ce 30.59%,
      #1aafd0 73.14%
    );
    background-clip: text;
  }

  &__text {
    margin-bottom: 44px;

    font-size: 13px;
    font-weight: 300;
    line-height: 1.46;

    color: map-get($colors, 'main');

    p:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__video {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 252px;
    height: 60px;

    font-size: 15px;
    font-weight: 500;

    border-radius: 70px;
    color: #fff;
    background-image: linear-gradient(113.2deg, #fd636b 10.25%, #ffd200 98.71%);

    transition: 0.2s;

    &:hover {
      box-shadow: 0px 0px 25px rgba(249, 159, 64, 0.9);
    }

    &::before {
      position: relative;
      top: 1px;

      width: 18px;
      height: 20px;
      margin-right: 10px;

      font-size: 20px;

      background-color: #fff;
      clip-path: polygon(0% 0%, 100% 50%, 0% 100%);

      content: '';
    }
  }

  &__slider {
    position: relative;

    .swiper-container {
      border-radius: 20px;
    }
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 85%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 20px;
    }
  }

  &__nav {
    display: none;
  }

  &__pagination {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 18px;

    display: flex;
    justify-content: center;
    width: 100%;

    .swiper-pagination-bullet {
      width: 11px;
      height: 11px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 11px;
      }

      &-active {
        background-color: #fff;
      }
    }
  }
}

@media (min-width: 768px) {
  .about {
    &__content {
      padding-top: 20px;
    }

    &__textual {
      margin-bottom: 60px;
    }

    &__title {
      align-self: flex-start;
      margin-bottom: 6px;
    }

    &__text {
      margin-bottom: 23px;
    }

    &__video {
      align-self: flex-start;
    }

    &__slide {
      padding-bottom: 66%;
    }
  }
}

@media (min-width: 1200px) {
  .about {
    #about {
      top: -91px;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      padding-top: 66px;
    }

    &__textual {
      width: calc(50% - 15px);
      margin-bottom: 0;
    }

    &__title {
      margin-bottom: 0;

      font-size: 45px;
      line-height: 1.56;
    }

    &__text {
      margin-bottom: 21px;

      font-size: 16px;
      line-height: 1.19;
    }

    &__video {
      width: 280px;
      height: 60px;
      margin-top: auto;

      font-size: 16px;
    }

    &__slider {
      width: calc(50% - 15px);
    }

    &__slide {
      padding-bottom: 88%;
    }

    &__nav {
      display: flex;
    }

    &__prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 18px;

      width: 72px;
      height: 72px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.7);
      transform: translateY(-50%);

      transition: 0.2s;

      &::before {
        position: absolute;
        top: 29px;
        left: 32px;

        width: 14px;
        height: 14px;

        border-left: 1px solid map-get($colors, 'main');
        border-bottom: 1px solid map-get($colors, 'main');
        border-radius: 1px;
        transform: rotate(45deg);

        content: '';
      }

      &:hover {
        background-color: #fff;
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    &__next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 18px;

      width: 72px;
      height: 72px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.7);
      transform: translateY(-50%);

      transition: 0.2s;

      &::before {
        position: absolute;
        top: 29px;
        right: 32px;

        width: 14px;
        height: 14px;

        border-right: 1px solid map-get($colors, 'main');
        border-bottom: 1px solid map-get($colors, 'main');
        border-radius: 1px;
        transform: rotate(-45deg);

        content: '';
      }

      &:hover {
        background-color: #fff;
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    &__pagination {
      bottom: 25px;
    }
  }
}
