.slider {
  position: relative;

  margin-bottom: 19px;

  :global {
    .swiper-container {
      border-radius: 15px;
    }
  }
}

.slide {
  position: relative;

  display: flex;

  padding-bottom: 80%;

  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 15px;
  }
}

:global {
  .calculation-swiper-nav {
    display: none;
  }

  .calculation-swiper-pagination {
    position: absolute;
    z-index: 10;
    left: 50%;
    bottom: 15px;

    display: flex;

    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 7px;
      height: 7px;

      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 7px;
      }

      &-active {
        background-image: linear-gradient(
          113.2deg,
          #fd636b 10.25%,
          #ffd200 98.71%
        );
      }
    }
  }
}

.thumbs {
  display: flex;

  margin: 0 -5px;
}

.thumb {
  position: relative;

  width: calc(33.33333% - 10px);
  margin: 0 5px;

  span {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    font-size: 26px;
    font-weight: 500;

    color: #fff;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);

    pointer-events: none;
  }
}

.thumbImage {
  position: relative;

  display: block;
  padding-bottom: 71%;

  img {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 10px;
  }
}

@media (min-width: 1200px) {
  .slider {
    margin-bottom: 23px;
  }

  .thumbs {
    margin: 0 -12px;
  }

  .thumb {
    width: calc(33.33333% - 24px);
    margin: 0 12px;
  }

  :global {
    .calculation-swiper-nav {
      display: flex;
    }

    .calculation-swiper-nav-prev {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 20px;

      width: 46px;
      height: 46px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      transform: translateY(-50%);

      transition: 0.2s;

      &::before {
        position: absolute;
        top: 17px;
        left: 20px;

        width: 11px;
        height: 11px;

        border-left: 1px solid #42426a;
        border-bottom: 1px solid #42426a;
        transform: rotate(45deg);

        content: '';
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }

    .calculation-swiper-nav-next {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 20px;

      width: 46px;
      height: 46px;

      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 9px 15px rgba(116, 145, 165, 0.23);
      transform: translateY(-50%);

      transition: 0.2s;

      &::before {
        position: absolute;
        top: 17px;
        right: 20px;

        width: 11px;
        height: 11px;

        border-right: 1px solid #42426a;
        border-top: 1px solid #42426a;
        transform: rotate(45deg);

        content: '';
      }

      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &:active {
        transform: translateY(-50%);
      }
    }
  }
}
