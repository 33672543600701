.module {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 442px;
  max-width: 100%;
  padding: 50px 25px 30px;

  border-radius: 10px;
  background-color: #fff;
}

.close {
  position: absolute;
  top: 14px;
  right: 7px;

  width: 40px;
  height: 40px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(45deg);

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 27px;
    height: 1px;

    border-radius: 1px;
    background-color: #aaa;
    transform: translate(-50%, -50%) rotate(-45deg);

    content: '';
  }
}

.grade {
  align-self: flex-start;
  margin-bottom: 10px;

  font-size: 16px;
  font-weight: 600;

  color: transparent;
  background-image: linear-gradient(135.61deg, #6a67ce 30.59%, #1aafd0 73.14%);
  background-clip: text;
}

.name {
  align-self: flex-start;
  margin-bottom: 19px;

  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;

  color: #42426a;
}

.text {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.58;
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 60px;
    width: 700px;
  }

  .grade {
    margin-bottom: 19px;
  }
}
