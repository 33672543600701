.header {
  $p: &;

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  width: 100%;

  transition: 0.2s;

  &__content {
    position: relative;

    display: flex;
    align-items: center;
    height: 66px;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30px;
    height: 40px;

    &::before {
      width: 13px;
      height: 1px;
      margin-bottom: 4px;

      background-color: #fff;

      content: '';
    }

    span {
      width: 13px;
      height: 1px;
      margin-bottom: 4px;

      background-color: #fff;
    }

    &::after {
      width: 13px;
      height: 1px;

      background-color: #fff;

      content: '';
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    margin-right: auto;

    &-white {
      width: 124px;
    }

    &-black {
      display: none;
      width: 124px;
    }
  }

  &__nav {
    display: none;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__phone {
    display: flex;
    // margin-right: 14px;

    font-size: 12px;
    font-weight: 700;
    line-height: 1.25;

    color: map-get($colors, 'main');
  }

  &__callback {
    display: none;
  }

  &--open {
    #{$p}__button {
      position: absolute;
      top: 8px;
      left: -15px;
      z-index: 10;

      align-items: center;
      width: 48px;
      height: 48px;

      &::before {
        width: 20px;
        height: 1px;
        margin: 0;

        border-radius: 1px;
        background-color: map-get($colors, 'main');
        transform: rotate(45deg);
      }

      span {
        display: none;
      }

      &::after {
        position: relative;
        top: -1px;

        width: 20px;
        height: 1px;

        border-radius: 1px;
        background-color: map-get($colors, 'main');
        transform: rotate(-45deg);
      }
    }

    #{$p}__nav {
      position: absolute;
      top: 0;
      left: -20px;

      display: flex;
      flex-direction: column;
      width: 220px;
      padding: 80px 20px 40px;

      background-color: #fff;

      a {
        color: map-get($colors, 'main');

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }

    // #{$p}__phone {
    //   position: absolute;
    //   left: 0;
    //   top: 320px;

    //   display: block;

    //   font-size: 15px;
    //   font-weight: 700;
    //   line-height: 1.2;

    //   color: map-get($colors, 'main');
    // }
  }

  &--sticky {
    background-color: #fff;

    #{$p}__button {
      &::before {
        background-color: map-get($colors, 'main');
      }

      span {
        background-color: map-get($colors, 'main');
      }

      &::after {
        background-color: map-get($colors, 'main');
      }
    }

    #{$p}__logo {
      &-white {
        display: none;
      }

      &-black {
        display: initial;
      }
    }

    #{$p}__callback {
      background-image: linear-gradient(
        113.2deg,
        #fd636b 10.25%,
        #ffd200 98.71%
      );

      svg {
        fill: #fff;
        stroke: #fff;

        path {
          fill: #fff;
          stroke: #fff;
        }
      }

      &:hover {
        box-shadow: 0px 0px 12px rgba(249, 159, 64, 0.9);
      }
    }
  }
}

@media (min-width: 375px) {
  .header {
    &__phone {
      margin-right: 11px;
    }

    &__callback {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 37px;
      height: 37px;

      border-radius: 50%;
      background-color: #fff;
    }
  }
}

@media (min-width: 768px) {
  .header {
    &__phone {
      display: flex;
      margin-right: 14px;

      font-size: 15px;
      font-weight: 700;

      color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 1200px) {
  .header {
    &__content {
      height: 91px;
    }

    &__button {
      display: none;
    }

    &__logo {
      &-white {
        width: 241px;
      }

      &-black {
        width: 241px;
      }
    }

    &__nav {
      display: flex;
      margin-right: 45px;

      &--mobile {
        display: none;
      }

      a {
        color: map-get($colors, 'main');
        transition: 0.2s;

        &:hover {
          color: transparent;
          background-image: linear-gradient(180deg, #f99f40, #fd636b);
          background-clip: text;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    &__phone {
      font-size: 18px;
    }

    &__callback {
      width: 50px;
      height: 50px;
      transition: 0.2s;

      &:hover {
        background-image: linear-gradient(
          113.2deg,
          #fd636b 10.25%,
          #ffd200 98.71%
        );

        svg {
          fill: #fff;
          stroke: #fff;

          path {
            fill: #fff;
            stroke: #fff;
          }
        }
      }
    }
  }
}
